import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <CustomVideoPlayer width="960" loop autoPlay src="https://github.com/user-attachments/assets/e088e2b0-c6a0-4f16-9bc5-43d7d634e009" mdxType="CustomVideoPlayer" />
    <h2>{`Usage`}</h2>
    <p>{`A skeleton box can be any size or shape, and may be used to render a skeleton loader to replace content that cannot accurately be replaced by a skeleton avatar or skeleton text component.`}</p>
    <p>{`For example, you may want to render a skeleton loader in place of a hero image in a content card that is still loading.`}</p>
    <h2>{`Options`}</h2>
    <p>{`You may customize the `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` dimensions of the skeleton box to match the size and shape of the content it is replacing.`}</p>
    <h2>{`Acccessibility`}</h2>
    <p>{`Refer to the loading pattern `}<a parentName="p" {...{
        "href": "/ui-patterns/loading#accessibility"
      }}>{`accessibility guidelines`}</a>{`. Specifically, the secion about `}<a parentName="p" {...{
        "href": "/ui-patterns/loading#avoiding-over-announcing-loading-states"
      }}>{`avoiding over-announcing loading states`}</a>{`.`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="SkeletonBox" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/loading"
        }}>{`Loading`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-avatar"
        }}>{`Skeleton avatar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-text"
        }}>{`Skeleton text`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      